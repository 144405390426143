import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const WrapperCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 180px;
  width: 100%;
`;
