import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { useSelector } from 'react-redux';

import { useLogin } from 'hooks';
import { history } from 'store';

import { Layout } from './styled';
import WithSessionRoutes from './with-session-routes';
import WithoutSessionRoutes from './without-session-routes';

const Routes = () => {
  const isLoadingToken = useSelector(store => store.authorization.isLoadingToken);
  const [isRequested, token, company] = useLogin();

  return (
    <Layout>
      {!isLoadingToken && isRequested && (
        <>
          {token && company && <WithSessionRoutes />}
          {!token && <WithoutSessionRoutes />}
        </>
      )}
    </Layout>
  );
};

export default Routes;
