import styled from 'styled-components';

import caretDown from 'assets/png/caret-down.png';

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 40px;
  @media screen and (max-width: 767px) {
    margin-right: 30px;
  }
`;

export const ArrowDown = styled.img.attrs({ src: caretDown, alt: 'arrow-down' })`
  height: 10px;
  transform: ${({ isOpen }) => (!isOpen ? 'translateY(0)' : 'rotate(0.5turn)')};
`;
