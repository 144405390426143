import { Row } from '@hivediversity/common-lib/base';
import PropTypes from 'prop-types';
import React from 'react';

import tooltipImage from 'assets/png/tooltip.png';
import { Tooltip } from 'components';

import { Bullet, BulletContainer, Label } from './styled';

const LabelWithBullet = ({ showBullet, label, tooltipMessage, style }) => (
  <Row center $bsStyle={style}>
    <Label>{label}</Label>
    <BulletContainer>{showBullet && <Bullet />}</BulletContainer>
    {tooltipMessage && (
      <div>
        <Tooltip
          text={tooltipMessage}
          image={tooltipImage}
          imageStyle={{ border: 'none', width: '15px' }}
          $bsStyle={{ width: '100%', minWidth: '200px' }}
          position="left"
        />
      </div>
    )}
  </Row>
);

LabelWithBullet.propTypes = {
  label: PropTypes.string.isRequired,
  tooltipMessage: PropTypes.string,
  showBullet: PropTypes.bool,
  style: PropTypes.shape({}),
};

LabelWithBullet.defaultProps = {
  showBullet: false,
  tooltipMessage: undefined,
  style: undefined,
};

export default LabelWithBullet;
