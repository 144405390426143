import styled from 'styled-components';

export const Label = styled.label`
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  color: white;
  font-size: 22px;
  margin-bottom: 10px;
  line-height: 17px;
  letter-spacing: 0.3px;
`;

export const Bullet = styled.div`
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  margin-left: 10px;
`;

export const BulletContainer = styled.div`
  max-width: 30px;
  width: 100%;
`;
