import PropTypes from 'prop-types';
import React from 'react';

import { Check } from 'components';

import { Container, WrapperCheckbox } from './styled';

const Checkboxes = ({ options, name, values, $bsStyle, onChange }) => {
  const findValue = option => values.find(value => value === option.id);
  const filterValue = option => values.filter(value => value !== option.id);

  const handleChange = option => {
    if (findValue(option)) {
      const nextValue = filterValue(option);
      onChange(name, nextValue);
    } else {
      const nextValue = values.concat(option.id);
      onChange(name, nextValue);
    }
  };

  return (
    <Container $bsStyle={$bsStyle}>
      {options.map(option => (
        <WrapperCheckbox key={`${option.id}-wrapper`}>
          <Check
            $bsStyle={{ marginBottom: '15px' }}
            name={name}
            value={findValue(option) || false}
            label={option.description}
            labelStyle={{
              margin: 'auto',
              paddingLeft: '10px',
              fontWeight: 420,
              fontSize: '14px',
              fontFamily: 'Brandon Grotesque',
              letterSpacing: '-0.01em',
              textTransform: 'uppercase',
              lineHeight: '21px',
            }}
            noFormik
            onChange={() => handleChange(option)}
          />
        </WrapperCheckbox>
      ))}
    </Container>
  );
};

Checkboxes.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
      description: PropTypes.string,
    })
  ),
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  $bsStyle: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
};

Checkboxes.defaultProps = {
  $bsStyle: undefined,
  options: [],
};

export default Checkboxes;
