import { Row } from '@hivediversity/common-lib/base';
import styled from 'styled-components';

import circleBackground from 'assets/png/circle-background.png';
import { DropdownContainer, Title } from 'styled';

export const ProgressBarContainer = styled.div`
  background: #5b37a8;
  border-radius: 34px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 25px;
`;

export const Bullet = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  border-radius: 35px;
  border: 3px solid #fff;
  margin-top: 2px;
  ${({ showImage }) =>
    showImage
      ? 'background: linear-gradient(180deg, #FFCF39 0%, #E9A623 100%)'
      : `background-image: url(${circleBackground})`};
  @media screen and (max-width: 767px) {
    width: 30px;
    height: 30px;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 17px;
  font-weight: 400;
  margin-top: 0;
  margin-left: 3px;
  @media screen and (max-width: 767px) {
    font-size: 17px;
    margin-top: 5px;
  }
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const StyledRow = styled(Row)`
  margin: 10px 0 10px 0;
  @media screen and (max-width: 350px) {
    justify-content: center;
  }
`;

export const BoxContainer = styled.div`
  background-color: #7249c9;
  border-radius: 24px;
  height: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 25px 25px 25px;
  padding: 30px 25px 30px 23px;
  min-width: 200px;
  width: 100%;
  flex: 1;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  @media screen and (max-width: 900px) {
    max-width: 800px;
  }
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const StyledDropdownContainer = styled(DropdownContainer)`
  max-height: 55px;
  min-width: 200px;
  width: 100%;
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const CollapsibleChildrenContainer = styled.div`
  overflow-y: auto;
`;
